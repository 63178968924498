import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSteps } from '../../components/StepController';
import KYCStatus from './KYCStatus';
import { KYCResponseType, PaymentsUrls } from '../../types';
import { UserContext } from '../../context/UserContext';
import { BannerContext } from '../../context/BannerContext';
import checkKYCPassed from './kycHelpers';

const KYCStarted = () => {
  const stepContext = useSteps();
  const { apiFetch } = useContext(UserContext);
  const [isRefreshingKycStatus, setIsRefreshingKycStatus] = useState(false);
  const { removeBanner } = useContext(BannerContext);

  const { refetch: fetchDataKycStatus, data: kycResponse } = useQuery<KYCResponseType>(
    ['kyc'],
    () => apiFetch(`${process.env.REACT_APP_API_PAYMENTS}v1/payments/account/get-capabilities`),
    {
      onSuccess() {
        setIsRefreshingKycStatus(false);
      },
    }
  );

  const { refetch: fetchUrls, data: kycUrls } = useQuery<PaymentsUrls>(['paymentsUrls'], () =>
    apiFetch(`${process.env.REACT_APP_API_PAYMENTS}v1/payments/banking/account/get-payments-urls?returnUrl=${window.location.href}`)
  );

  useEffect(() => {
    removeBanner('kycInProgress');
  }, []);

  useEffect(() => {
    if (!kycResponse || !stepContext?.updateCurrentStep) return;
    stepContext.updateCurrentStep({
      error: '',
      completable: checkKYCPassed(kycResponse),
    });
  }, [kycResponse]);

  const refreshKycStatus = () => {
    setIsRefreshingKycStatus(true);
    fetchDataKycStatus().then();
  };

  return (
    <KYCStatus
      isRefreshingKycStatus={isRefreshingKycStatus}
      kycResponse={kycResponse}
      refreshKycStatus={refreshKycStatus}
      kycUrls={kycUrls}
    />
  );
};

export default KYCStarted;
